@import './_colors';
@import './_spacing';

.employee_image {
  position: relative;
  overflow: hidden;
  margin-bottom: $size-small;
  // clip-path: url(#ghost);
  border-radius: 50% 50% 0 0;
  clip-path: polygon(100% 0, 100% 100%, 83.2% 90%, 66.6% 100%, 50% 90%, 33.3% 100%, 16.6% 90%, 0 100%, 0 0);

  // .employee_image-bottom:before, .employee_image-bottom:after, &:before, &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   bottom: -16%;
  //   transform: rotate(45deg);
  //   width: 25%;
  //   height: 25%;
  //   background-color: $background-color;
  //   border-radius: 50%;
  //   z-index: 8;
  // }

  // &:after {
  //   right: 0;
  // }

  // .employee_image-bottom:before {
  //   left: 25%;
  // }
  // .employee_image-bottom:after {
  //   right: 25%;
  // }

  img {
    width: 100%;
    height: auto;
  }

  .ghost {
    position: absolute;
  }
}
