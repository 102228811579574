@import './_colors';
@import './_spacing';
@import './_media';

.article {
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: $primary-light;
    opacity: 0;

    transition: opacity .2s ease;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.article_detail {
  position: fixed;
  top: 97px;
  left: 0;
  max-height: calc(100vh - 97px);
  width: 100%;
  z-index: 9;
  opacity: 0;
  transition: opacity .3s ease .3s;

  &:not(.hidden) {
    opacity: 1;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }

  .button_close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .article_content {
    padding: 2rem;
  }
}

.article_detail-content {
  position: relative;
  background-color: $white;
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
  max-height: calc(100vh - 97px);
  overflow-y: auto;
}

.article_detail-buttons {
  display: flex;
  justify-content: space-between;

  object {
    display: inline-block;
  }
}

@media (max-width: $screen-large) {
  .articles {
    margin-left: -$size-medium;
    width: calc(100% + 4rem);
  }

  .article_detail {
    top: 86px;
    max-height: calc(100vh - 86px);
  }

  .article_detail-content {
    width: 90%;
    max-height: calc(100vh - 86px);
  }
}

@media (max-width: $screen-medium) {
  .article {
    width: 100%;
  }

  .articles {
    width: 100%;
  }
}