@import './_colors.scss';
@import './_types.scss';

.loader {
  position: absolute;
  background-color: $grey-light;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 11;
  display: flex;
  transition: opacity .7s ease-out 1.5s, visibility .7s ease-out 1.5s;
  display: none;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  span {
    position: absolute;
    bottom: -2rem;
    width: 100%;
    text-align: center;
    font-size: .8rem;
    font-family: $font-display;
  }
}

.spinner {
  width: 5rem;
  height: 5rem;

  position: relative;
  margin: auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $primary-medium;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}