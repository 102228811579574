@import './_media';
@import './_spacing';
@import './_colors';
@import './_animations';

.secondary-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $primary-dark;
	z-index: 9;
	box-shadow: 0 2px 7px rgba(181, 181, 181, 0.2);
	transition: top $timing-medium $easing;
	border-bottom: 1px solid $grey-light;
  display: flex;

	&.float {
		top: 100px;
	}

	&.hidden {
		top: -5rem;
	}

  a {
    color: $white;
    fill: $white;
    padding: $size-small;
    border-bottom: 3px solid transparent;
    white-space: nowrap;
    transition: color $timing-medium $easing, border-color $timing-medium $easing;

    &:hover {
      color: $grey-light;
      fill: $grey-light;
      border-color: $grey-light;
    }

    &.active {
      color: $white;
      fill: $white;
      border-color: $white;
    }
  }
}

.secondary-nav_inner {
	display: flex;
	overflow-x: auto;
  overflow-y: visible;
  padding-left: 1rem;
  padding-right: 1rem;
  
  &::-webkit-scrollbar {
    width: .4rem;
    height: .4rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent; 
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-light;
    transition: background .2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey-medium;
  }
}

@media (max-width: $screen-large) {
  .secondary-nav {

    &.float {
      top: 98px;
    }
  }

  .secondary-nav_inner {
    justify-content: flex-start;
    padding-left: 0;
  }
} 
