@import './_colors';

.card {
  cursor: pointer;

  > *:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: $primary-light;
    opacity: 0;

    transition: opacity .2s ease;
  }

  &:hover {
    > *:after {
      opacity: 1;
    }
  }
}

.card_title {
  .external {
    vertical-align: middle;
  }
}