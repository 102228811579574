.primary-lightest {
  background-color: #cfe4ef; }

.primary-light {
  background-color: #bfd9e4; }

.primary-medium {
  background-color: #a2bfcc; }

.primary-dark {
  background-color: #85a1ad; }

.primary-darkest {
  background-color: #547382; }

.grey-1 {
  background-color: #f3f3f3; }

.grey-2 {
  background-color: #dce1e4; }

.grey-3 {
  background-color: #b2b6b7; }

.grey-4 {
  background-color: #82898c; }

.grey-5 {
  background-color: #656b6d; }

.twitter-feed_container {
  background-color: #fff; }
  .twitter-feed_container [class*="heading"] {
    border-bottom: 1px solid #dce1e4; }
  .twitter-feed_container .twitter {
    vertical-align: middle; }

.twitter-feed_timeline {
  height: 75vh;
  overflow-y: auto; }

[id*="twitter-widget"] {
  width: 100% !important; }
