.blog-widget_music {
	position: relative;
	height: 100%;

	img { width: 100%; }


	.playlist {
		height: 100%;
		width: 100%;
		margin-top: 0;

		iframe { width: 100%; height: 100%; }
	}

	// a .blog-widget_image {
	// 	position: relative;

	// 	&:before, &:after {
	// 		content: '';
	// 		display: block;
	// 		position: absolute;
	// 		top: 50%;
	// 		transform: translate(-50%, -50%);
	// 		opacity: 50%;
	// 		transition: opacity .2s ease;
	// 		z-index: 9;
	// 	}

	// 	&:after {
	// 		background: url('../../assets/images/music_icon.svg') no-repeat center;
	// 		background-size: contain;
	// 		width: 2rem;
	// 		height: 2rem;
	// 		position: absolute;
	// 		left: calc(50% - .1rem);
	// 	}

	// 	&:before {
	// 		background-color: white;
	// 		border-radius: 50%;
	// 		width: 5rem;
	// 		height: 5rem;
	// 		left: 50%;
	// 	}

	// 	&:hover {
	// 		&:before, &:after {
	// 			opacity: 1;
	// 		}
	// 	}
	// }
}
