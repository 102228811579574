.pagination {
  text-align: center;

  .page {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .active {
    text-decoration: underline;
  }
}