.primary-lightest {
  background-color: #cfe4ef; }

.primary-light {
  background-color: #bfd9e4; }

.primary-medium {
  background-color: #a2bfcc; }

.primary-dark {
  background-color: #85a1ad; }

.primary-darkest {
  background-color: #547382; }

.grey-1 {
  background-color: #f3f3f3; }

.grey-2 {
  background-color: #dce1e4; }

.grey-3 {
  background-color: #b2b6b7; }

.grey-4 {
  background-color: #82898c; }

.grey-5 {
  background-color: #656b6d; }

.slider {
  padding-left: 3rem;
  overflow-x: auto; }
  .slider > * > * {
    flex: 1;
    max-width: 25%; }
  .slider::-webkit-scrollbar {
    width: .4rem;
    height: .4rem; }
  .slider::-webkit-scrollbar-track {
    background: transparent;
    margin: 0 1rem; }
  .slider::-webkit-scrollbar-thumb {
    background: #dce1e4;
    transition: background .2s ease; }
  .slider::-webkit-scrollbar-thumb:hover {
    background: #b2b6b7; }

@media (max-width: 900px) {
  .slider {
    padding-left: 0; }
    .slider > * > * {
      max-width: 100%; } }

.blog-provider_logo {
  width: 6rem; }

.blog-widget_image {
  margin-bottom: 1rem; }

[data-oembed-provider="Spotify"], .playlist {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem; }

[data-oembed-provider="YouTube"], .youtube {
  margin-bottom: 1rem;
  text-align: center; }
  [data-oembed-provider="YouTube"] iframe, .youtube iframe {
    max-width: 100%;
    width: 100%;
    height: 100%; }

[data-oembed-provider="Instagram"] {
  width: 100%;
  margin-bottom: 1rem; }
  [data-oembed-provider="Instagram"] .instagram-media {
    width: 100% !important;
    min-width: auto !important;
    max-width: 500px !important;
    margin: 0 auto !important; }

.blog_head {
  margin-bottom: 2rem; }
  .blog_head .heading {
    margin-bottom: 0.5rem; }
  .blog_head .body-medium {
    margin-bottom: 0.5rem; }

.blog_body p {
  margin-bottom: 1rem; }

.blog_body .youtube {
  text-align: center; }

.blog_body .blog_featured-image {
  margin: 0 auto 2rem; }

.blog {
  max-width: 800px; }

.blog_social {
  margin: 0 auto;
  display: flex; }
  .blog_social .social_share {
    margin-right: 0.5rem; }

.blog_featured-image {
  margin-bottom: 2rem; }
  .blog_featured-image.small {
    max-width: 400px; }
  .blog_featured-image.medium {
    max-width: 500px; }
  .blog_featured-image.large {
    max-width: 600px; }

.blog-provider_logo {
  margin-right: 1rem; }

.tag {
  background-color: #a2bfcc;
  padding: .3rem;
  color: #fff;
  display: inline-block;
  margin-bottom: 0.5rem; }
