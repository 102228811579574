@import './_animations';
@import './_colors';
@import './_media';
@import './_spacing';

.feature-container_events {
  .feature-container_items {
    display: flex;
    padding-bottom: $size-small;
  }

  .featured-item_event {
    width: 33%;
    padding: $size-medium;
    background-color: $grey-light;
    margin: 0 $size-small;
    color: $black-light;
    transition: box-shadow $timing-medium ease;

    &:hover {
      box-shadow: 5px 4px 0px $primary-light;
    }
  }

  .button {
    margin-top: $size-small;
  }
}

@media (max-width: $screen-large) {
  .feature-container_events {
    padding: 0;

    .heading {
      padding-left: $size-small;
      padding-right: $size-small;
    }

    .feature-container_items {
      padding: 0 0 0 $size-small;
      width: 230vw;
      margin-bottom: $size-medium;
    }

    .featured-item_event {
      width: 60vw;
      margin-left: 0;
      margin-right: $size-small;
    }
  }
}