@import './_media';
@import './_spacing';

.feature-container_news {
  padding: 0;

  .feature-container_items {
    flex-direction: column;
  }
}

.loaded {
  .featured-news {
    min-height: auto;
  }
}

.featured-news {
  display: flex;
  margin-bottom: $size-xlarge;
  min-height: 85vh;

  .featured-item_image {
    display: flex;
    width: 60%;

    > * {
      flex: 1;
    }
  }

  p {
    margin-bottom: $size-small;
  }

  img {
    width: 100%;
  }

  .featured-item_content {
    width: 40%;
    padding: 5rem;
  }
}

@media (min-width: $screen-large) {
  .featured-news {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .feature-container_news {
    .heading {
      padding-left: 5rem;
    }

    .featured-item_image {
      height: 500px;
      overflow: hidden;
    }
  }
}

@media (max-width: $screen-large) {
  .feature-container_news {
    .heading {
      padding-left: $size-small;
      padding-right: $size-small;
    }
  }

  .featured-news {
    flex-direction: column;
    margin-bottom: $size-medium;

    &:last-of-type {
      margin-bottom: 0;
    }

    .featured-item_image {
      width: 100%;

      img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        height: 100%;
      }
    }

    .featured-item_content {
      width: auto;
      padding: $size-small;
    }
  }
}
