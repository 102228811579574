.primary-lightest {
  background-color: #cfe4ef; }

.primary-light {
  background-color: #bfd9e4; }

.primary-medium {
  background-color: #a2bfcc; }

.primary-dark {
  background-color: #85a1ad; }

.primary-darkest {
  background-color: #547382; }

.grey-1 {
  background-color: #f3f3f3; }

.grey-2 {
  background-color: #dce1e4; }

.grey-3 {
  background-color: #b2b6b7; }

.grey-4 {
  background-color: #82898c; }

.grey-5 {
  background-color: #656b6d; }

.primary-lightest {
  background-color: #cfe4ef; }

.primary-light {
  background-color: #bfd9e4; }

.primary-medium {
  background-color: #a2bfcc; }

.primary-dark {
  background-color: #85a1ad; }

.primary-darkest {
  background-color: #547382; }

.grey-1 {
  background-color: #f3f3f3; }

.grey-2 {
  background-color: #dce1e4; }

.grey-3 {
  background-color: #b2b6b7; }

.grey-4 {
  background-color: #82898c; }

.grey-5 {
  background-color: #656b6d; }

input, textarea, select {
  font-family: inherit;
  border: 1px solid #b2b6b7;
  padding: .5rem;
  font-size: 1rem;
  margin-bottom: 1rem; }
  input:focus, textarea:focus, select:focus {
    border-color: #656b6d;
    outline: none; }

select {
  margin-bottom: 0; }

input[type="submit"] {
  border: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  margin-bottom: 0; }

label {
  margin-bottom: 0.5rem; }

textarea {
  min-height: 6rem;
  resize: vertical; }

input[type="checkbox"] {
  display: none; }
  input[type="checkbox"]:checked + span {
    border-color: #bfd9e4;
    background-color: #bfd9e4; }
    input[type="checkbox"]:checked + span:after {
      content: ''; }

.checkbox {
  flex-direction: row-reverse;
  cursor: pointer; }
  .checkbox span {
    position: relative;
    width: 1rem;
    height: 1rem;
    border: 2px solid #bfd9e4;
    margin-right: .5rem; }
    .checkbox span:after {
      display: block;
      position: absolute;
      left: .3rem;
      width: 5px;
      top: 1px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }

select {
  line-height: 1.5;
  height: auto;
  border-radius: 0; }

option {
  padding: .5rem; }

.form_input {
  transition: margin-bottom 0.5s ease; }

.form_error {
  color: #ff6161;
  padding-bottom: 1rem; }
  .form_error input, .form_error textarea {
    background-color: #ffe7e7;
    border-color: #ff6161; }

.form_error-message {
  color: #ff6161;
  font-size: .8rem;
  margin-top: -1rem;
  margin-bottom: 0;
  opacity: 0;
  animation: slide 0.5s forwards; }

.form_error-response {
  padding: 1rem;
  border: 1px solid #ff6161;
  background-color: #ffe7e7; }

.form_success-response {
  padding: 1rem;
  border: 1px solid #77e277;
  background-color: #e9ffe9; }

.contact {
  max-width: 1000px;
  margin: 0 auto; }

@keyframes slide {
  100% {
    margin-top: -0.5rem;
    opacity: 1; } }

.lds-ring {
  display: inline-block;
  position: relative;
  width: .9rem;
  height: .9rem; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: .6rem;
  height: .6rem;
  margin: 5px;
  border: 3px solid #bfd9e4;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #bfd9e4 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.grid-container {
  display: flex; }

.wrap {
  flex-wrap: wrap; }

.column {
  flex-direction: column; }

.grid-1 {
  width: 100%; }

@media (min-width: 400px) {
  .grid-1-s {
    width: 100%; } }

@media (min-width: 700px) {
  .grid-1-m {
    width: 100%; } }

@media (min-width: 900px) {
  .grid-1-l {
    width: 100%; } }

@media (min-width: 1200px) {
  .grid-1-xl {
    width: 100%; } }

.grid-2 {
  width: 50%; }

@media (min-width: 400px) {
  .grid-2-s {
    width: 50%; } }

@media (min-width: 700px) {
  .grid-2-m {
    width: 50%; } }

@media (min-width: 900px) {
  .grid-2-l {
    width: 50%; } }

@media (min-width: 1200px) {
  .grid-2-xl {
    width: 50%; } }

.grid-3 {
  width: 33.33333%; }

@media (min-width: 400px) {
  .grid-3-s {
    width: 33.33333%; } }

@media (min-width: 700px) {
  .grid-3-m {
    width: 33.33333%; } }

@media (min-width: 900px) {
  .grid-3-l {
    width: 33.33333%; } }

@media (min-width: 1200px) {
  .grid-3-xl {
    width: 33.33333%; } }

.grid-4 {
  width: 25%; }

@media (min-width: 400px) {
  .grid-4-s {
    width: 25%; } }

@media (min-width: 700px) {
  .grid-4-m {
    width: 25%; } }

@media (min-width: 900px) {
  .grid-4-l {
    width: 25%; } }

@media (min-width: 1200px) {
  .grid-4-xl {
    width: 25%; } }

.grid-5 {
  width: 20%; }

@media (min-width: 400px) {
  .grid-5-s {
    width: 20%; } }

@media (min-width: 700px) {
  .grid-5-m {
    width: 20%; } }

@media (min-width: 900px) {
  .grid-5-l {
    width: 20%; } }

@media (min-width: 1200px) {
  .grid-5-xl {
    width: 20%; } }

.grid-6 {
  width: 16.66667%; }

@media (min-width: 400px) {
  .grid-6-s {
    width: 16.66667%; } }

@media (min-width: 700px) {
  .grid-6-m {
    width: 16.66667%; } }

@media (min-width: 900px) {
  .grid-6-l {
    width: 16.66667%; } }

@media (min-width: 1200px) {
  .grid-6-xl {
    width: 16.66667%; } }

.grid-7 {
  width: 14.28571%; }

@media (min-width: 400px) {
  .grid-7-s {
    width: 14.28571%; } }

@media (min-width: 700px) {
  .grid-7-m {
    width: 14.28571%; } }

@media (min-width: 900px) {
  .grid-7-l {
    width: 14.28571%; } }

@media (min-width: 1200px) {
  .grid-7-xl {
    width: 14.28571%; } }

.grid-8 {
  width: 12.5%; }

@media (min-width: 400px) {
  .grid-8-s {
    width: 12.5%; } }

@media (min-width: 700px) {
  .grid-8-m {
    width: 12.5%; } }

@media (min-width: 900px) {
  .grid-8-l {
    width: 12.5%; } }

@media (min-width: 1200px) {
  .grid-8-xl {
    width: 12.5%; } }

.grid-9 {
  width: 11.11111%; }

@media (min-width: 400px) {
  .grid-9-s {
    width: 11.11111%; } }

@media (min-width: 700px) {
  .grid-9-m {
    width: 11.11111%; } }

@media (min-width: 900px) {
  .grid-9-l {
    width: 11.11111%; } }

@media (min-width: 1200px) {
  .grid-9-xl {
    width: 11.11111%; } }

.grid-10 {
  width: 10%; }

@media (min-width: 400px) {
  .grid-10-s {
    width: 10%; } }

@media (min-width: 700px) {
  .grid-10-m {
    width: 10%; } }

@media (min-width: 900px) {
  .grid-10-l {
    width: 10%; } }

@media (min-width: 1200px) {
  .grid-10-xl {
    width: 10%; } }

.grid-30 {
  width: 30%; }

.grid-70 {
  width: 70%; }

.column {
  flex-direction: column; }

@media (min-width: 900px) {
  .grid-30-large {
    width: 30%; }
  .grid-70-large {
    width: 70%; } }

.primary-lightest {
  background-color: #cfe4ef; }

.primary-light {
  background-color: #bfd9e4; }

.primary-medium {
  background-color: #a2bfcc; }

.primary-dark {
  background-color: #85a1ad; }

.primary-darkest {
  background-color: #547382; }

.grey-1 {
  background-color: #f3f3f3; }

.grey-2 {
  background-color: #dce1e4; }

.grey-3 {
  background-color: #b2b6b7; }

.grey-4 {
  background-color: #82898c; }

.grey-5 {
  background-color: #656b6d; }

a {
  color: #547382;
  fill: #547382;
  text-decoration: none;
  transition: color 0.3s cubic-bezier(0, 1, 0.5, 1); }
  a:hover {
    color: #425b67;
    fill: #425b67; }

button, .button {
  width: auto;
  display: inline-block;
  cursor: pointer;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  border: 2px solid #bfd9e4;
  background-color: #fff;
  color: #232323;
  transition: color 0.3s cubic-bezier(0, 1, 0.5, 1), background-color 0.3s cubic-bezier(0, 1, 0.5, 1), border-color 0.3s cubic-bezier(0, 1, 0.5, 1); }
  button:hover, .button:hover {
    color: #fff;
    border-color: #a2bfcc;
    background-color: #a2bfcc; }
  button.disabled, .button.disabled {
    opacity: .5; }

.light a {
  color: #bfd9e4; }

.light button, .light .button {
  color: #fff;
  background-color: transparent;
  border-color: #bfd9e4; }
  .light button *, .light .button * {
    color: #fff; }
  .light button:hover, .light .button:hover {
    color: #232323;
    background-color: #bfd9e4; }

.button_close {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  z-index: 9;
  background-color: #fff;
  fill: #232323; }

html {
  font-family: "Open Sans";
  font-size: 16px; }

p {
  font-size: 1rem;
  line-height: 1.3rem; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

.body-small {
  font-size: 0.8rem;
  color: #656b6d; }

.body-medium, .body-medium p {
  font-size: 1.2rem; }

.heading, h1 {
  font-family: "Reem Kufi";
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block; }

.heading2, h2 {
  font-family: "Reem Kufi";
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 1rem; }

.heading3, h3 {
  font-family: "Reem Kufi";
  font-size: 1.3rem;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 0.5rem; }

.heading4, h4 {
  font-family: "Open Sans";
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem; }

.light {
  color: #fff; }

.bold {
  font-weight: 700; }

.paragraph > * {
  max-width: 1000px; }

@media (min-width: 900px) {
  html {
    font-family: "Open Sans";
    font-size: 18px; } }

html {
  overflow-x: hidden; }
  html.no-scroll {
    overflow: hidden; }

body {
  color: #232323;
  overflow-x: hidden;
  background-color: #f7f7f7;
  transition: opacity .5s ease-in; }

section {
  padding: 3rem 3rem 0; }

.page-error {
  text-align: center;
  padding-top: 10rem; }

.flex {
  display: flex; }

.content {
  margin-top: 5.56rem;
  min-height: calc(100vh - 100px - 205px); }

.navigation_float + .content {
  margin-top: 0; }

.center-vertical {
  display: flex;
  align-items: center; }

.paragraph p {
  margin-bottom: 1rem; }

[class*="responsive-image"] {
  position: relative; }
  [class*="responsive-image"] img {
    width: 100%; }

.center-align {
  text-align: center;
  display: block; }

.right-align {
  text-align: right;
  display: block; }

.cover {
  overflow: hidden; }
  .cover img, .cover div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }

.padding-xsmall {
  padding: 0.5rem; }

.padding-top-xsmall {
  padding-top: 0.5rem; }

.padding-right-xsmall {
  padding-right: 0.5rem; }

.padding-bottom-xsmall {
  padding-bottom: 0.5rem; }

.padding-left-xsmall {
  padding-left: 0.5rem; }

.padding-vertical-xsmall {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.padding-horizontal-xsmall {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.margin-xsmall {
  margin: 0.5rem; }

.margin-top-xsmall {
  margin-top: 0.5rem; }

.margin-right-xsmall {
  margin-right: 0.5rem; }

.margin-bottom-xsmall {
  margin-bottom: 0.5rem; }

.margin-left-xsmall {
  margin-left: 0.5rem; }

.margin-vertical-xsmall {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.margin-horizontal-xsmall {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.padding-small {
  padding: 1rem; }

.padding-top-small {
  padding-top: 1rem; }

.padding-right-small {
  padding-right: 1rem; }

.padding-bottom-small {
  padding-bottom: 1rem; }

.padding-left-small {
  padding-left: 1rem; }

.padding-vertical-small {
  padding-left: 1rem;
  padding-right: 1rem; }

.padding-horizontal-small {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.margin-small {
  margin: 1rem; }

.margin-top-small {
  margin-top: 1rem; }

.margin-right-small {
  margin-right: 1rem; }

.margin-bottom-small {
  margin-bottom: 1rem; }

.margin-left-small {
  margin-left: 1rem; }

.margin-vertical-small {
  margin-left: 1rem;
  margin-right: 1rem; }

.margin-horizontal-small {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.padding-medium {
  padding: 2rem; }

.padding-top-medium {
  padding-top: 2rem; }

.padding-right-medium {
  padding-right: 2rem; }

.padding-bottom-medium {
  padding-bottom: 2rem; }

.padding-left-medium {
  padding-left: 2rem; }

.padding-vertical-medium {
  padding-left: 2rem;
  padding-right: 2rem; }

.padding-horizontal-medium {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.margin-medium {
  margin: 2rem; }

.margin-top-medium {
  margin-top: 2rem; }

.margin-right-medium {
  margin-right: 2rem; }

.margin-bottom-medium {
  margin-bottom: 2rem; }

.margin-left-medium {
  margin-left: 2rem; }

.margin-vertical-medium {
  margin-left: 2rem;
  margin-right: 2rem; }

.margin-horizontal-medium {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.padding-large {
  padding: 3rem; }

.padding-top-large {
  padding-top: 3rem; }

.padding-right-large {
  padding-right: 3rem; }

.padding-bottom-large {
  padding-bottom: 3rem; }

.padding-left-large {
  padding-left: 3rem; }

.padding-vertical-large {
  padding-left: 3rem;
  padding-right: 3rem; }

.padding-horizontal-large {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.margin-large {
  margin: 3rem; }

.margin-top-large {
  margin-top: 3rem; }

.margin-right-large {
  margin-right: 3rem; }

.margin-bottom-large {
  margin-bottom: 3rem; }

.margin-left-large {
  margin-left: 3rem; }

.margin-vertical-large {
  margin-left: 3rem;
  margin-right: 3rem; }

.margin-horizontal-large {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.padding-xlarge {
  padding: 4rem; }

.padding-top-xlarge {
  padding-top: 4rem; }

.padding-right-xlarge {
  padding-right: 4rem; }

.padding-bottom-xlarge {
  padding-bottom: 4rem; }

.padding-left-xlarge {
  padding-left: 4rem; }

.padding-vertical-xlarge {
  padding-left: 4rem;
  padding-right: 4rem; }

.padding-horizontal-xlarge {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.margin-xlarge {
  margin: 4rem; }

.margin-top-xlarge {
  margin-top: 4rem; }

.margin-right-xlarge {
  margin-right: 4rem; }

.margin-bottom-xlarge {
  margin-bottom: 4rem; }

.margin-left-xlarge {
  margin-left: 4rem; }

.margin-vertical-xlarge {
  margin-left: 4rem;
  margin-right: 4rem; }

.margin-horizontal-xlarge {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.margin-none {
  margin: 0; }

.padding-none {
  padding: 0; }

.padding-bottom-none {
  padding-bottom: 0; }

.padding-vertical-none {
  padding-left: 0;
  padding-right: 0; }

.padding-top-none {
  padding-top: 0; }

.char-limit {
  max-width: 900px; }

@media (min-width: 900px) {
  .padding-left-large-l {
    padding-left: 3rem; }
  .padding-right-large-l {
    padding-right: 3rem; }
  .padding-vertical-small-l {
    padding-right: 1rem;
    padding-left: 1rem; }
  .padding-vertical-medium-l {
    padding-right: 2rem;
    padding-left: 2rem; }
  .padding-vertical-large-l {
    padding-right: 3rem;
    padding-left: 3rem; }
  .margin-right-small-l {
    margin-right: 1rem; }
  .padding-medium-l {
    padding: 2rem; }
  .margin-bottom-xlarge-l {
    margin-bottom: 4rem; }
  .margin-bottom-medium-l {
    margin-bottom: 2rem; }
  .margin-top-medium-l {
    margin-top: 2rem; } }

@media (min-width: 700px) {
  .margin-right-small-m {
    margin-right: 1rem; }
  .padding-medium-m {
    padding: 2rem; }
  .padding-vertical-medium-m {
    padding-left: 2rem;
    padding-right: 2rem; }
  .padding-vertical-small-m {
    padding-left: 1rem;
    padding-right: 1rem; } }

@media (max-width: 900px) {
  .column-s {
    flex-direction: column; }
  .break-column-s {
    margin-left: -1rem;
    margin-right: -1rem; }
  .padding-vertical-small-s {
    padding-right: 1rem;
    padding-left: 1rem; }
  .margin-top-large-s {
    margin-top: 3rem; }
  .padding-vertical-xsmall-s {
    padding-right: 0.5rem;
    padding-left: 0.5rem; } }

@media (min-width: 900px) {
  .responsive-image_mobile {
    display: none; } }

@media (max-width: 900px) {
  section {
    padding: 2rem 1rem 0; }
  .content {
    margin-top: 6.1rem;
    min-height: calc(100vh - 98px - 248px); }
  .break-out-small {
    margin-left: -0.5rem;
    margin-right: -0.5rem; } }

@media (max-width: 899px) {
  .responsive-image_desktop {
    display: none; } }
