@import './_animations';
@import './_colors';
@import './_media';
@import './_spacing';

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: $white;
  box-shadow: 0 2px 7px rgba(181, 181, 181, 0.2);

  transition: background-color $timing-slow $easing, box-shadow $timing-slow $easing, color $timing-slow $easing;

  svg {
    transition: fill $timing-slow $easing;    
  }

  .bandcamp {
    color: $white;
    fill: $black;
  }
}

.navigation-logo {
  height: 80px;
}

.navigation_float:not(.navigation_show) {
  background-color: transparent;
  box-shadow: none;
  color: $white;
}

.navigation_show {
  background-color: $white;
  box-shadow: 0 2px 7px rgba(181, 181, 181, 0.2);
}

.navigation-container {
  display: flex;
  justify-content: space-between;
}

.logo-container {
  padding: $size-xsmall $size-medium;
}

.logo {
  fill: $black-light;
  width: 10rem;
}

.navigation-links {
  display: flex;
}

.navigation-link {
  display: flex;
  align-items: center;
  padding: $size-small;
  transition: color $timing-medium $easing, background-color $timing-medium $easing;

  &:hover, &.active {
    color: $white;
    background-color: $primary-darkester;
  }
}

.navigation-link_icon {
  display: flex;
  align-items: center;
  margin-left: $size-small;

  &:last-of-type {
    margin-right: $size-small;
  }

  // &:hover {
  //   svg {fill: $primary-darkester;}
  // }
}

.navigation-button {
  cursor: pointer;
  width: 50px;
  height: 21px;
  align-self: center;
  padding: $size-small;
}

.navigation-button_close {
  cursor: pointer;
  align-self: flex-end;
  padding: $size-small $size-small 0;
  color: $black-light;
}

@media (max-width: $screen-large) {
  .navigation {
    &.hidden {
      .navigation-links {
        right: -100%;
      }

      .navigation-overlay {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .logo-container {
    padding: $size-xsmall $size-small;
  }

  .navigation-links {
    position: absolute;
    right: 0;
    height: 100vh;
    align-items: flex-start;
    background-color: $white;
    flex-direction: column;
    width: 300px;
    font-size: 1.3rem;
    transition: right $timing-medium ease;
    z-index: 1;
  }

  .navigation-link {
    width: 100%;
    padding: $size-small $size-medium;
  }

  .navigation-link_icon {
    margin: $size-small $size-medium 0;
  }

  .navigation-overlay {
    cursor: pointer;
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    background-color: rgba(0, 0, 0, .8);
    transition: opacity $timing-medium ease, background-color $timing-medium ease;

    &:hover {
      background-color: rgba(0, 0, 0, .9);
    }
  }

  .navigation_float:not(.navigation_show) {
    .logo, .navigation-button {
      fill: $white;

      &:hover, &.active {fill: $primary-medium;}
    }
  }
}

@media (max-width: $screen-small) {
  .navigation-links {
    width: 75vw;
  }
}

@media (min-width: $screen-large) {
  .navigation_float:not(.navigation_show) {
    .navigation-link {
      color: $white;

      &:hover, &.active {
        color: $primary-medium;
        background-color: transparent;
      }
    }

    svg {
      fill: $white;

      &:hover, &.active {fill: $primary-medium;}
    }

    .bandcamp {
      color: $black;
    }
  }

  .navigation-links {
    padding-right: $size-small;
  }

  .navigation-button, .navigation-button_close {
    display: none;
  }
}

.device-large {
  .navigation-links {
    display: flex;
    align-items: stretch;
  }
}