@import './_colors';
@import './_media';
@import './_spacing';

.artist {
  cursor: pointer;

  > *:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: $primary-light;
    opacity: 0;

    transition: opacity .2s ease;
  }

  &:hover {
    > *:after {
      opacity: 1;
    }
  }
}

.artist_detail {
  // position: fixed;
  // top: 97px;
  // width: 100%;
  // height: calc(100vh - 97px);
  // left: 0;
  // z-index: 11;
  // cursor: pointer;
  // opacity: 0;
  // transition: opacity .3s ease;

  // &:not(.hidden) {
  //   opacity: 1;

  //   .overlay {
  //     background-color: rgba(0, 0, 0, .8);
  //   }
  // }

  .artist_social {
    img {
      height: $size-medium;
    }
  }
}

.artist_albums {
  .featured-item {
    width: auto;
  }
}

.artist_social {
  display: flex;
  align-items: center;
}

@media (min-width: $screen-large) {
  .artist_albums {
    .heading2 {
      padding-left: $size-medium;
      padding-right: $size-medium;
    }
  }
}

@media (max-width: $screen-xlarge - 1) {
  .artist_albums {
    .grid-container {
      padding-left: $size-medium;
      padding-right: $size-medium;
    }
  }
}

@media (max-width: $screen-large - 1) {
  .artist_albums {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (max-width: $screen-large - 1) {
  .artist_image {
    flex-direction: column;
  }

  .artist_social {
    margin-bottom: 3rem;
  }

  .artist_albums {
    .featured-item {
      padding-left: $size-xsmall;
      padding-right: $size-xsmall;
    }
  }
}