@import './_animations';
@import './_colors';
@import './_media';
@import './_spacing';

.hero {
  display: flex;
  height: 75vh;
  margin-bottom: $size-large;
  overflow: hidden;
}

.hero-item {
  position: relative;
  display: flex;
  flex-grow: 1;
  opacity: 1;
  min-width: $size-medium;
  transition: flex-grow $timing-slow ease;

  &:hover {
    flex-grow: 20;

    .overlay {
      background-color: transparent;
    }

    .hero-item_content {
      opacity: 1;
      transition: opacity 0.5s $timing-medium ease;
    }
  }

  &.right {
    .hero-item_content {
      left: auto;
      right: 10%;
    }
  }

  &.center {
    .hero-item_content {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.extra.dark {
    .hero-item_content {
      background-color: $grey-lightest;
    }
  }

  &.extra.light {
    .hero-item_content {
      background-color: $grey-darkest;
    }
  }
}

.hero-item_image {
  display: flex;
  flex: 1;

  > * {
    min-height: 100%;
    min-width: 100vw;
  }
}

.hero-item_content {
  position: absolute;
  left: 10%;
  bottom: 15%;
  max-width: 60%;
  max-height: 50%;
  padding: 1.5rem;
  opacity: 0;
  transition: opacity $timing-slow ease;

  // a {
  //   margin-top: $size-medium;
  // }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  transition: background-color $timing-slow ease;
}

@media (min-width: $screen-large) {
  .hero { margin-bottom: $size-medium; }
  .hero-item { min-width: 0; }
}