@import './_colors';
@import './_media';
@import './_spacing';

.footer {
  margin-top: $size-xlarge;
  padding: $size-medium $size-xlarge + 1rem;
  background-color: #f1f1f1;
}

.footer-container {
  display: flex;
}

.footer-links_social {
  align-items: center;
}

.footer-links {
  margin-top: $size-small;
}

.footer-link {
  margin-right: $size-small;
}

.footer-link_icon {
  margin: 0 0.25rem;
}

@media (max-width: $screen-large) {
  .footer {
    padding: $size-medium $size-small;
  }

  .footer-container {
    flex-direction: column;
  }

  .footer-links_social {
    margin-top: $size-medium;
  }

  .footer-link_icon {
    margin: 0 $size-xsmall;
  }
}

@media (min-width: $screen-large) {
  .footer-links_social {
    display: flex;
    justify-content: flex-end;
  }
}