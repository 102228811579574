$font-display: 'Reem Kufi';
$font-body: 'Open Sans';

$body-size-s: 1rem;
$body-size-m: 1rem;
$body-size-l: 1rem;

$body-height-s: 1.3rem;
$body-height-m: 1.3rem;
$body-height-l: 1.3rem;

$heading-size-s: 3rem;
$heading-size-m: 3rem;
$heading-size-l: 3rem;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 600;
$weight-bold: 700;