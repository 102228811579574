@import './_colors';
@import './_media';
@import './_spacing';
@import './_types';

.feature-container {
  padding-left: 0;
  padding-right: 0;
}

.loaded {
  .featured-item {
    min-height: auto;
  }
}

.featured-item {
  padding: 0 $size-small $size-medium 0;
  color: $black-light;
  flex-direction: column;
  flex: 1;
  min-height: 96vh;

  &:hover {
    color: $black-light;
  }

  p {
    font-weight: $weight-light;
  }

  .body-small {
    margin-bottom: $size-small;
    font-family: $font-display;
  }

  img {
    width: 100%;
    margin-bottom: $size-small
  }
}

.scroller .feature-container_items {
  width: 130vw;
  left: -15vw;
  position: relative;
  
}

.moving .feature-container_items {
  transition: transform .2s ease;
}

.scroller_indicators {
  text-align: center;
}

.scroller {
  position: relative;
  // overflow-x: auto;
}

.feature-container .scroller {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.scroller_button {
  border: none;
  height: 65px;
  position: absolute;
  z-index: 9;
  top: 10rem;
  transform: translate(0, -100%);

  &:focus {
    outline: none;
  }

  &.right {
    right: 0;
  }

  &.left {
    transform: rotate(-180deg) translate(0, 100%);
  }

  &:after, &:before {
    position: absolute;
    right: 0;
    content: '';
    display: block;
    width: 30px;
    height: 4px;
    background-color: black;
    transform-origin: left;
  }

  &:before {
    top: 10px;
    transform: rotate(45deg);
  }

  &:after {
    bottom: 10px;
    transform: rotate(-45deg);
  }
}

@media (max-width: $screen-large) {
  [class*="feature-container"] {
    .heading {
      padding: 0 0 $size-small;
    }
  }

  .feature-container {
    padding-right: 0;
    padding-left: 0;

    .heading {
      padding-left: $size-small;
      padding-right: $size-small;
    }

    .feature-container_items {
      width: 192vw;
      padding-left: $size-small;
    }
  }

  .featured-item {
    width: 60vw;
  }

  .scroller {
    // overflow-x: auto;

    &::-webkit-scrollbar {
      width: .4rem;
      height: .4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent; 
      margin: 0 $size-small;
    }

    &::-webkit-scrollbar-thumb {
      background: $grey-light;
      transition: background .2s ease;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $grey-medium;
    }
  }

  .scroller_button {
    display: none;
  }
}

@media (min-width: $screen-large) {
  [class*="feature-container"] {
    .heading {
      padding: 0 $size-medium $size-small;
    }
  }

  .scroller_indicators {
    display: none;
  }
}

@media (min-width: $screen-xlarge) {
  .featured-item {
    padding: 0 2rem 1rem;
  }
}
