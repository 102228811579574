@import './_spacing';
@import './_colors';

.breadcrumbs {
	display: flex;
	align-items: center;
	margin-bottom: $size-medium;
	font-size: .8rem;

	 > * {
	 	display: flex;
	 }
}

.breadcrumbs_separator {
	display: block;
	position: relative;
	width: 1rem;
	// height: 10px;

	&:after, &:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: .5rem;
		width: 1px;
		height: 8px;
		background-color: $black-light;
	}

	&:before {
		top: 1px;
		transform: rotate(-45deg);
	}

	&:after {
		top: 6px;
		transform: rotate(45deg);
	}
}