@import './_colors';
@import './_media';
@import './_spacing';
@import './_types';

.filter-control {
  position: absolute;
  padding-top: $size-small;
  width: calc(100% - 10rem);
  transition: background-color .3s ease;
  display: flex;
  flex-direction: column;

  &.float {
    position: fixed;
    left: 0;
    top: 97px;
    padding-left: 5rem;
    padding-right: 5rem;
    background-color: $grey-lightest;
    box-shadow: 0 2px 7px rgba(181, 181, 181, 0.2);
    z-index: 9;
  }

  .filters {
    display: flex;
    overflow-x: auto;
    padding-bottom: $size-small;

     > * {
      display: flex;
     }
  }

  .checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 1rem;
  }
}
[class^="filter-control"] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.filter-control_dropdown {
  margin-right: $size-small;
}

.filter-control_selectmulti {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .checkbox {
    margin-bottom: .5rem;
  }
}

.filters {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-bottom: 0;
  display: flex;
}

.filter-small {
  position: relative;
  display: flex;
  margin-left: auto;
  // margin-right: 1rem;
  background-color: $primary-darkest;

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-weight: $weight-medium;
  }

  svg {
    padding-left: .5rem;
  }

  .filters {
    align-items: flex-start;
  }
}

.filter-small_dropdown-container {
  position: absolute;
  max-height: 0;
  top: 100%;
  right: 0;
  // font-size: .8rem;
  transition: max-height .5s ease;
  overflow: hidden;

  &.open {
    max-height: 400px;
  }
}

.filter-small_dropdown-container-inner {
  background-color: $primary-darkest;
  padding: 1rem;
  padding-right: 0;
  box-shadow: 0 2px 7px rgba(181, 181, 181, 0.2);
  color: $white;
}

@media (max-width: $screen-large) {
  .filter-control {
    width: calc(100% - 2rem);

    &.float {
      top: 98px;
      padding-left: $size-small;
      padding-right: $size-small;

      .filters {
        margin-left: -$size-small;
        margin-right: -$size-small;
        padding-left: $size-small;
        padding-right: $size-small;
      }
    }
  }
}
