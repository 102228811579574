.blog-widget_video {
	position: relative;

	img { width: 100%; }

	// a .blog-widget_image {
	// 	position: relative;

	// 	&:before, &:after {
	// 		content: '';
	// 		display: block;
	// 		position: absolute;
	// 		top: 50%;
	// 		left: 50%;
	// 		transform: translate(-50%, -50%);
	// 		opacity: 50%;
	// 		transition: opacity .2s ease;
	// 		z-index: 9;
	// 	}

	// 	&:after {
	// 		border-top: 1rem solid transparent;
	// 	  border-bottom: 1rem solid transparent;
		  
	// 	  border-left: 1.5rem solid red;
	// 		// background-color: red;
	// 		width: 0;
	// 		height: 0;
	// 	}

	// 	&:before {
	// 		background-color: white;
	// 		border-radius: 50%;
	// 		width: 5rem;
	// 		height: 5rem;
	// 	}

	// 	&:hover {
	// 		&:before, &:after {
	// 			opacity: 1;
	// 		}
	// 	}
	// }
}
