$white: #fff;
$black: #000;
$black-light: #232323;
$background-color: #f7f7f7;

$primary-lightest: #cfe4ef;
$primary-light: #bfd9e4;
$primary-medium: #a2bfcc;
$primary-dark: #85a1ad;
$primary-darkest: #547382;
$primary-darkester: #425b67;

$secondary-darkest: #d64b4b;
$secondary-darkester: #bb4141;

$grey-lightest: #f3f3f3;
$grey-light: #dce1e4;
$grey-medium: #b2b6b7;
$grey-dark: #82898c;
$grey-darkest: #656b6d;

$error: #ff6161;
$error-light: #ffe7e7;
$success: #77e277;
$success-light: #e9ffe9;

.primary-lightest {background-color: $primary-lightest;}
.primary-light {background-color: $primary-light;}
.primary-medium {background-color: $primary-medium;}
.primary-dark {background-color: $primary-dark;}
.primary-darkest {background-color: $primary-darkest;}
.grey-1 {background-color: $grey-lightest;}
.grey-2 {background-color: $grey-light;}
.grey-3 {background-color: $grey-medium;}
.grey-4 {background-color: $grey-dark;}
.grey-5 {background-color: $grey-darkest;}