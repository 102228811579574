@import './_animations';
@import './_colors';
@import './_spacing';

.svg {
  fill: currentColor;
  transition: all $timing-fast;
  -webkit-transition-timing-function: $easing;
  transition-timing-function: $easing;
}

.svg--xsmall {
  width: $size-xsmall;
  height: $size-xsmall;
}

.svg--small {
  width: $size-small;
  height: $size-small;
}

.svg--medium {
  width: $size-medium;
  height: $size-medium;
}

.svg--large {
  width: $size-large;
  height: $size-large;
}

// Social
.facebook {
  fill: #3b5998;
  margin-left: -10px;
  margin-right: -10px;
}

.twitter {
  fill: #1b95e0;
}

.instagram {
  fill: #D73185;
}

.bandcamp {
  fill: $black;
  color: $white;
}

.reddit {
  fill: #FF4500;
}

.external {
  vertical-align: bottom;
  margin-bottom: 1px;
}
